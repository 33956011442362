import React from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import HeaderFilterCollegueRoles from '../Header/HeaderFilterCollegueRoles'
import ProfileImg from "../images/profileImg.jpg"
import WhiteLocation from "../images/icons/white-location.svg"
import Responsive from '../Responsive'

export default function Colleagues() {
    return (
        <div>
            <div className='content-area'>
                <Header />
                <div className="component-area">
                    <Sidebar />
                    <div className="pt-96 pb-96 my-container active-cont">
                        <div className="container">
                            <HeaderFilterCollegueRoles />
                            <ColleaguesReturn />
                        </div>
                    </div>
                </div>
            </div>
            <Responsive />
        </div>
    )
}

const ColleaguesReturn = () => (
    <div className="row mt-md-4 mt-85 plr-40">
        <div className="col-md-12">
            <h2 className="module-title mb-3">Find colleagues</h2>
            <div className="table-container">
                <table id="" className="table-details">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Date</th>
                            <th>Zone</th>
                            <th>Spot</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div className="name-with-profile">
                                    <span><img src={ProfileImg} alt="" /></span>
                                    <h3>Daire O Brien</h3>
                                </div>
                            </td>
                            <td>Meeting room</td>
                            <td>Mon, 24 Feb</td>
                            <td>Main Office Lower St Ballbrigan</td>
                            <td>UIR23</td>
                            <td>
                                <a href="#" className="green-filled-with-icon2"
                                ><span><img src={WhiteLocation} alt="" /></span>Map</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="name-with-profile">
                                    <span><img src={ProfileImg} alt="" /></span>
                                    <h3>Daire O Brien</h3>
                                </div>
                            </td>
                            <td>Meeting room</td>
                            <td>Mon, 24 Feb</td>
                            <td>Main Office Lower St Ballbrigan</td>
                            <td>UIR23</td>
                            <td>
                                <a href="#" className="green-filled-with-icon2"
                                ><span><img src={WhiteLocation} alt="" /></span>Map</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="name-with-profile">
                                    <span><img src={ProfileImg} alt="" /></span>
                                    <h3>Daire O Brien</h3>
                                </div>
                            </td>
                            <td>Meeting room</td>
                            <td>Mon, 24 Feb</td>
                            <td>Main Office Lower St Ballbrigan</td>
                            <td>UIR23</td>
                            <td>
                                <a href="#" className="green-filled-with-icon2"
                                ><span><img src={WhiteLocation} alt="" /></span>Map</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="name-with-profile">
                                    <span><img src={ProfileImg} alt="" /></span>
                                    <h3>Daire O Brien</h3>
                                </div>
                            </td>
                            <td>Meeting room</td>
                            <td>Mon, 24 Feb</td>
                            <td>Main Office Lower St Ballbrigan</td>
                            <td>UIR23</td>
                            <td>
                                <a href="#" className="green-filled-with-icon2"
                                ><span><img src={WhiteLocation} alt="" /></span>Map</a>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="name-with-profile">
                                    <span><img src={ProfileImg} alt="" /></span>
                                    <h3>Daire O Brien</h3>
                                </div>
                            </td>
                            <td>Meeting room</td>
                            <td>Mon, 24 Feb</td>
                            <td>Main Office Lower St Ballbrigan</td>
                            <td>UIR23</td>
                            <td>
                                <a href="#" className="green-filled-with-icon2"
                                ><span><img src={WhiteLocation} alt="" /></span>Map</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
)
