import { useContext } from "react";
import { TeamsFxContext } from "./Context";
import config from "./sample/lib/config";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
// import Login from "./Login";
// import Tabview from "./Tabview";
// import LanguageSelector from "./i18n/language-selector";
import { useTranslation  } from "react-i18next";

// import SettingReact from "./Demo/Setting/SettingReact";
import SettingDesign from "./Demo/Setting/SettingDesign";

// import Sidebar1 from "./Demo/Sidebar/SidebarTwo";
// import Sidebar from "./Demo/Sidebar/Sidebar";

// import Calendar from "./Demo/Calendar/Calendar";
import CalendarDesign from "./Demo/Calendar/CalendarDesign";
// import Calendartest from "./Demo/Calendar/Calendartest";


// import Booking from "./Demo/Booking/Booking";
// import NewBooking from "./Demo/Booking/NewBooking";
import BookingDesign from "./Demo/Booking/BookingDesign";

// import Colleagues from "./Demo/Colleagues/Colleagues";
import ColleaguesDesign from "./Demo/Colleagues/ColleaguesDesign";

// import EmployeeRoles from "./Demo/EmployeeRoles/EmployeeRoles";
// import Calendar1 from "./Demo/Calendar/CheckIn"
// import IFrame from "./Demo/Iframe";
// import i18n from "./i18n/i18n";
// import NewLogin from "./Demo/DemoToken/components/Login"

// import Emp from "./Demo/EmployeeRoles/Emp"
// import Emp1 from "./Demo/EmployeeRoles/Emp1"
import EmpDesign from "./Demo/EmployeeRoles/EmpDesign"

const showFunction = Boolean(config.apiName);

export default function Tab() {

  const {t} = useTranslation();

  const { themeString } = useContext(TeamsFxContext);
  return (
    <Router>
        {/* <IFrame/> */}
        <Switch>
          {/* <Route path="/calendar" component={Calendar} /> */}
          <Route path="/calendar" component={CalendarDesign} />
          {/* <Route path="/calendar" component={Calendartest} /> */}
          
          {/* <Route path="/booking" component={NewBooking} /> */}
          <Route path="/booking" component={BookingDesign} />

          {/* <Route path="/colleagues" component={Colleagues} /> */}
          <Route path="/colleagues" component={ColleaguesDesign} />
          
          {/* <Route path="/roles" component={Emp} /> */}
          {/* <Route path="/roles" component={Emp1} /> */}
          <Route path="/roles" component={EmpDesign} />
          {/* <Route path="/roles" component={EmployeeRoles} /> */}

          {/* <Route path="/setting" component={SettingReact} /> */}
          <Route path="/setting" component={SettingDesign} />
          <Redirect from="/" to="/calendar" />
        </Switch>
    </Router>
  );
}
