
import React from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import EmployeeRolesHeader from '../EmployeeRoles/EmployeeRolesHeader'
import WhiteLocation from "../images/icons/white-location.svg"
import GreenLeader from "../images/icons/green-leader.svg"
import ListIcon from "../images/icons/list-icon.svg"
import Responsive from '../Responsive'

export default function EmployeeRoles() {
    return (
        <div>
            <div className='content-area'>
                <Header />
                <div className="component-area">
                    <Sidebar />
                    <EmployeeRolesReturn />
                </div>
            </div>
            <Responsive />
        </div>
    )
}

const EmployeeRolesReturn = () => (

    <div className="pt-96 pb-96 my-container active-cont">
        <div className="container">
            <div className="row">
                <div className="col-xl-9 offset-xl-3 col-lg-10 offset-lg-2">
                    <div className="filter-panel d-flex justify-content-end align-items-center gap-3">
                        <div className="filter-options d-flex gap-3 w-100">
                            <div className="employee-list">
                                <div className="dropdown form-input-container d-flex justify-content-center flex-column">
                                    <button className="dropdown-toggle form-control position-relative custom-dropdown-btn" type="button" id="emp-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false" name="empdropdown" required>
                                        <p className="sub-dropdown-name">Role</p>
                                        <span>All Roles</span>
                                    </button>
                                    <ul className="dropdown-menu custom-dropdown" aria-labelledby="emp-dropdown" id="emp-dropdown">
                                        <li className="selected"><a className="dropdown-item" >Ronspot Admin</a></li>
                                        <li><a className="dropdown-item" >Ronspot Accountant</a></li>
                                        <li><a className="dropdown-item" >Ronspot Developer</a></li>
                                        <li><a className="dropdown-item" >Ronspot Designer</a></li>
                                        <li><a className="dropdown-item" >Ronspot Manager</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="booking-date align-items-center d-flex">
                                <label for="datepicker-calender"
                                >Calendar
                                    <input type="text" id="datepicker-calender" autocomplete="off" placeholder="All bookings" />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-md-4 mt-85 g-5 plr-40">
                <div className="col-md-4">
                    <div className="role-boxes">
                        <div className="role-icon">
                            <img src={WhiteLocation} alt="" />
                        </div>
                        <div className="emp-name">
                            <h3>Angela Hernandez</h3>
                        </div>
                        <div className="emp-place-info">
                            <div id="" className="selected-current-date">
                                <span>23 JUNE</span> <span><span className="color-green">D9</span> Dublin 3rd Floor</span>
                            </div>
                        </div>
                        <div className="role-area-wrapper">
                            <div className="role-area">
                                <img src={GreenLeader} alt="" />
                                <p>Ronspot Admin</p>
                                <a href="#" className="green-outline-badge"><span>+5</span></a>
                            </div>
                            <div className="list-container">
                                <ul>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Admin </span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Accountant</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Developer</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Designer</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Manager </span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="role-boxes">
                        <div className="role-icon">
                            <img src={WhiteLocation} alt="" />
                        </div>
                        <div className="emp-name">
                            <h3>Angela Hernandez</h3>
                        </div>
                        <div className="emp-place-info">
                            <div id="" className="selected-current-date">
                                <span>23 JUNE</span> <span><span className="color-green">D9</span> Dublin 3rd Floor</span>
                            </div>
                        </div>
                        <div className="role-area-wrapper">
                            <div className="role-area">
                                <img src={GreenLeader} alt="" />
                                <p>Ronspot Admin</p>
                                <a href="#" className="green-outline-badge"><span>+5</span></a>
                            </div>
                            <div className="list-container">
                                <ul>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Admin </span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Accountant</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Developer</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Designer</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Manager </span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="role-boxes">
                        <div className="role-icon">
                            <img src={WhiteLocation} alt="" />
                        </div>
                        <div className="emp-name">
                            <h3>Angela Hernandez</h3>
                        </div>
                        <div className="emp-place-info">
                            <div id="" className="selected-current-date">
                                <span>23 JUNE</span> <span><span className="color-green">D9</span> Dublin 3rd Floor</span>
                            </div>
                        </div>
                        <div className="role-area-wrapper">
                            <div className="role-area">
                                <img src={GreenLeader} alt="" />
                                <p>Ronspot Admin</p>
                                <a href="#" className="green-outline-badge"><span>+5</span></a>
                            </div>
                            <div className="list-container">
                                <ul>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Admin </span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Accountant</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Developer</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Designer</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Manager </span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="role-boxes">
                        <div className="role-icon">
                            <img src={WhiteLocation} alt="" />
                        </div>
                        <div className="emp-name">
                            <h3>Angela Hernandez</h3>
                        </div>
                        <div className="emp-place-info">
                            <div id="" className="selected-current-date">
                                <span>23 JUNE</span> <span><span className="color-green">D9</span> Dublin 3rd Floor</span>
                            </div>
                        </div>
                        <div className="role-area-wrapper">
                            <div className="role-area">
                                <img src={GreenLeader} alt="" />
                                <p>Ronspot Admin</p>
                                <a href="#" className="green-outline-badge"><span>+5</span></a>
                            </div>
                            <div className="list-container">
                                <ul>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Admin </span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Accountant</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Developer</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Designer</span></li>
                                    <li><img src={ListIcon} alt="" className="img-fluid" /> <span> Ronspot Manager </span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)