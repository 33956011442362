import { useState } from 'react'
import "../css/bootstrap.min.css"
import "../css/common.css"
import "../css/style.css"
import "../css/calendar.css"
import "../css/reponsive.css"

import orangestar from "../images/icons/orange-star.svg"
import notification from "../images/icons/notification.svg"
import profileImg from "../images/profileImg.jpg"
import edit from "../images/icons/edit.svg"
import logoutwhite from "../images/icons/logout-white.svg"
import Profile from "../images/profile.png"
import ProfileLocation from "../images/icons/profile-location.svg"
import FillStar from "../images/icons/fill-star.svg"
import GrayStar from "../images/icons/gray-star.svg"
import BlackClose from "../images/icons/black-close.svg"

import { useTranslation } from 'react-i18next'

export default function Header() {

    const employeeOptions = [
        "Meeting room",
        "Desk",
        "Parking",
        "Assets",
    ];

    const maps = [
        "Galway City Center North",
        "CK Office",
        "Parking Galway",
        "Grand Office Canal",
        "Dublin 3rd Floor",
        "Dublin Office Ground Floor",
    ]
    const {t} = useTranslation()
    return (
        // <div>
        <header className="header">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-3">
                        <div className="greeting-title">
                            <p>{t("goodmorning")} Michael !</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <form action="" className="d-flex justify-content-center custom-inline-dropdown gap-3">
                            <div className="dropdown form-input-container d-flex justify-content-center flex-column">
                                {/* <Dropdown
                                    dropdownBtnId="emp-dropdown-btn"
                                    options={employeeOptions}
                                    defaultText="Desk" /> */}
                                <button className="dropdown-toggle form-control position-relative custom-dropdown-btn" type="button" id="desk-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false" name="deskdropdown" required>
                                    <span>Desk</span>
                                </button>
                                <ul className="dropdown-menu custom-dropdown" aria-labelledby="desk-dropdown" id="desk-dropdown">
                                    <li className="selected"><a className="dropdown-item" href="javascript:void(0);">Meeting room</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);">Desk</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);">Parking</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);">Assets</a></li>
                                </ul>
                            </div>
                            <div className="dropdown form-input-container d-flex justify-content-center flex-column">
                                {/* <Dropdown
                                    dropdownBtnId="emp-dropdown-btn"
                                    options={maps}
                                    defaultText="CK office"
                                /> */}
                                <button className="dropdown-toggle form-control position-relative custom-dropdown-btn" type="button" id="area-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false" name="areadropdown" required>
                                    <span>Dublin Office Ground Floor</span>
                                </button>
                                <ul className="dropdown-menu custom-dropdown" aria-labelledby="area-dropdown" id="area-dropdown">
                                    <li className="selected"><a className="dropdown-item" href="javascript:void(0);">Galway City Center North</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);">CK Office</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);">Parking Galway</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);">Grand Office Canal</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);">Dublin 3rd Floor</a></li>
                                    <li><a className="dropdown-item" href="javascript:void(0);">Dublin Office Ground Floor</a></li>
                                </ul>
                            </div>
                        </form>
                    </div>
                    {/* <div className="col-md-3">
                            <div className="menu-bar-options d-flex justify-content-end gap-3">
                                <div className="credit-info">
                                    <a href="#" className="orange-outline-with-icon"><img src={orangestar} alt="" /><span>5 Credits</span></a>
                                </div>
                                <div className="notification-info">
                                    <a href="#" data-bs-toggle="offcanvas" data-bs-target="#notification-panel" aria-controls="notification-panel" className="position-relative"><img src={notification} alt="" /><span className="orange-dot"></span></a>
                                </div>
                                <div className="profile-info">
                                    <div className="dropdown dropdwon-green">
                                        <a href="#" className="profileImg" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src={profileImg} alt="" /></a>

                                        <ul className="dropdown-menu profile-dropdown" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a className="dropdown-item" href="#"
                                                ><span className="withIcon"><img src={edit} alt="" /></span><span className="withTitle">Settings</span></a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#"
                                                ><span className="withIcon"><img src={logoutwhite} alt="" /></span><span className="withTitle">Log out</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    <div className="col-md-3">
                        <div className="menu-bar-options d-flex justify-content-end gap-3">
                            <div className="credit-info">
                                <a href="#" className="orange-outline-with-icon"><img src={orangestar} alt="" /><span>5 Credits</span></a>
                                <div className="credit-info-inner text-center">
                                    <div className="profile-info-inner">
                                        <div className="profile-img">
                                            <img src={Profile} alt="" className="img-fluid" />
                                        </div>
                                        <div className="profile-name">
                                            <h5>Michael Furey</h5>
                                        </div>
                                        <div className="profile-location d-flex justify-content-center align-items-center">
                                            <img src={ProfileLocation} alt="" />
                                            <p>Galway.IE</p>
                                        </div>
                                    </div>
                                    <div className="credit-balance-info">
                                        <div className="credit-balance d-flex justify-content-between align-items-center">
                                            <p>Credit balance</p>
                                            <h6>14</h6>
                                        </div>
                                        <div className="credit-balance-btn">The credit balance reset <a href="#"> every month </a></div>
                                    </div>
                                    <div className="credit-details-lists">
                                        <div className="list-card">
                                            <img src={FillStar} alt="" className="img-fluid" />
                                            <p>Use credits to book spaces</p>
                                        </div>
                                        <div className="list-card">
                                            <img src={GrayStar} alt="" className="img-fluid" />
                                            <p>Same day, no credits needed</p>
                                        </div>
                                        <div className="list-card">
                                            <img src={BlackClose} alt="" className="img-fluid" />
                                            <p>Release booking for refund</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="notification-info">
                                <a href="#" data-bs-toggle="offcanvas" data-bs-target="#notification-panel" aria-controls="notification-panel" className="position-relative"><img src={notification} alt="" /><span className="orange-dot"></span></a>
                            </div>
                            <div className="profile-info">
                                <div className="dropdown dropdwon-green">
                                    <a href="#" className="profileImg" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><img src={profileImg} alt="" /></a>

                                    <ul className="dropdown-menu profile-dropdown" aria-labelledby="dropdownMenuButton1">
                                        <li>
                                            <a className="dropdown-item" href="#"
                                            ><span className="withIcon"><img src={edit} alt="" /></span><span className="withTitle">Settings</span></a>
                                        </li>
                                        <li>
                                            <a className="dropdown-item" href="#"
                                            ><span className="withIcon"><img src={logoutwhite} alt="" /></span><span className="withTitle">Log out</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}



function Dropdown({ dropdownBtnId, options, defaultText }) {
    const [selectedText, setSelectedText] = useState(defaultText);
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleItemClick = (text) => {
        setSelectedText(text);
        setIsOpen(false); // Close the dropdown after selection
    };

    return (
        <div className="employee-list">
            <div className="dropdown form-input-container d-flex justify-content-center flex-column">
                <button
                    className="dropdown-toggle form-control position-relative custom-dropdown-btn"
                    type="button"
                    id={dropdownBtnId}
                    onClick={handleToggle}
                    aria-expanded={isOpen}
                    required
                >
                    {/* <p className="sub-dropdown-name">Book for</p> */}
                    <span>{selectedText}</span>
                </button>
                {isOpen && (
                    <ul>
                        {options.map((option, index) => (
                            <li
                                key={index}
                                className={selectedText === option ? "dropdown-item" : ""}
                                onClick={() => handleItemClick(option)}
                            >
                                <a className="dropdown-item" href="#!">
                                    {option}
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}