import FullCalendar from '@fullcalendar/react';
import dayGridMonth from '@fullcalendar/daygrid';
import timeGridWeek from '@fullcalendar/timegrid';
import { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Responsive from '../Responsive';
import Header from '../Header/Header';
import HeaderFilter from '../Header/HeaderFilter';
import HeaderFilterNew from "../HeaderFilternew";
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from 'bootstrap/js/dist/modal';
import "../css/bootstrap.min.css";

import Close from "../images/icons/close.svg";
import OrangeStar from "../images/icons/orange-star.svg";
import GreenMap from "../images/icons/green-map.svg";
import EditSquare from "../images/icons/edit-square.svg";
import MapOrange from "../images/icons/map-orange.svg";

const Calendar = () => {
    const [calendarEl, setCalendarEl] = useState(null);
    const [weeklyPercentages, setWeeklyPercentages] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [currentDate, setCurrentDate] = useState(new Date());
    const [events, setEvents] = useState([
        { id: '1', title: 'Spot 3467TY - All day', start: '2024-12-09', status: 'checked-out' },
        { id: '2', title: 'Spot WER 234 - 10:50 - 12:00', start: '2024-12-10', status: 'available' },
        { id: '3', title: 'Spot WER 234 - 10:50 - 12:00', start: '2024-12-10', status: 'available' },
        { id: '4', title: 'Spot WER 234 - 10:50 - 12:00', start: '2024-12-10', status: 'available' },
        { id: '5', title: 'Spot WER 234 - 10:50 - 12:00', start: '2024-12-10', status: 'available' },
        // Additional spots as events
    ]);
    const [showModal, setShowModal] = useState(false);

    const [upcomingBookings, setUpcomingBookings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setWeeklyPercentages(generateUniquePercentages(7, 10, 30));
        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formattedDate = currentDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
    });

    const formattedOnlyDate = currentDate.toLocaleDateString('en-US', {
        day: 'numeric',
    });

    const formattedOnlyMonth = currentDate.toLocaleDateString('en-US', {
        month: 'long',
    });
    const formattedOnlyMonthShort = currentDate.toLocaleDateString('en-US', {
        month: 'short',
    });

    const formattedOnlyYear = currentDate.toLocaleDateString('en-US', {
        year: 'numeric'
    });

    const handleEventClick = (clickInfo) => {
        const updatedEvents = events.map((event) => {
            if (event.id === clickInfo.event.id) {
                return {
                    ...event,
                    status: event.status === 'available' ? 'checked-in' : 'available',
                };
            }
            return event;
        });
        setEvents(updatedEvents);
    };

    const handleCheckInButtonClick = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleViewMount = (info) => {
        if (info.view.type === 'timeGridWeek') {
            formatTimeGridWeekHeaders(info.el);
            renderCustomAllDayHTML(info.el);
            renderCustomNavigationButtons(info.el);
            addPercentageData(info.el);
        }
    };

    function generateUniquePercentages(count, min, max) {
        var percentages = new Set();
        while (percentages.size < count) {
            var percentage = Math.floor(Math.random() * (max - min + 1)) + min;
            percentages.add(percentage);
        }
        return Array.from(percentages);
    }

    function formatTimeGridWeekHeaders() {
        var headerEls = document.querySelectorAll(".fc-col-header th");
        headerEls.forEach(function (headerEl) {
            var date = new Date(headerEl.getAttribute("data-date"));
            var dayName = new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(date);
            var dayDate = ("0" + date.getDate()).slice(-2); // Add leading zero
            headerEl.innerHTML = `<div class="name-date-header text-center"><div class="day-name">${dayName}</div><div class="day-date">${dayDate}</div></div>`;
        });
    }

    function renderCustomAllDayHTML() {
        var allDayRow = document.querySelector(".fc-col-header .fc-daygrid-header-row");
        if (allDayRow) {
            var firstCol = allDayRow.querySelector("th:first-child");
            if (firstCol) {
                firstCol.innerHTML = `<div class="custom-all-day">Custom All-Day HTML</div>`;
            }
        }
    }

    function renderCustomNavigationButtons() {
        var timeGridView = document.querySelector(".fc-timeGridWeek-view");
        if (timeGridView) {
            var existingButtons = document.querySelector(".custom-navigation-buttons");
            if (!existingButtons) {
                var buttonContainer = document.createElement("div");
                buttonContainer.className = "custom-navigation-buttons";

                var prevButton = document.createElement("button");
                prevButton.className = "custom-prev-week-button fc-button fc-prev-button";
                prevButton.innerHTML = "<span></span>";
                prevButton.addEventListener("click", function () {
                    // calendar.prev(); // Go to previous week
                });

                var nextButton = document.createElement("button");
                nextButton.className = "custom-next-week-button fc-button fc-next-button";
                nextButton.innerHTML = "<span></span>";
                nextButton.addEventListener("click", function () {
                    // calendar.next(); // Go to next week
                });

                buttonContainer.appendChild(prevButton);
                buttonContainer.appendChild(nextButton);

                timeGridView.insertBefore(buttonContainer, timeGridView.firstChild);
            }
        } else {
            console.error(".fc-timeGridWeek-view not found.");
        }
    }

    function areAllDaysInPast(view) {
        var currentDate = view.currentStart;
        var today = new Date();
        today.setHours(0, 0, 0, 0); // Midnight for accurate comparison

        var allDaysInPast = true;
        for (var i = 0; i < 7; i++) {
            var dateToCheck = new Date(currentDate);
            dateToCheck.setDate(dateToCheck.getDate() + i);
            if (dateToCheck >= today) {
                allDaysInPast = false;
                break;
            }
        }
        return allDaysInPast;
    }

    function generateUniquePercentages(count, min, max) {
        var percentages = new Set();
        while (percentages.size < count) {
            var percentage = Math.floor(Math.random() * (max - min + 1)) + min;
            percentages.add(percentage);
        }
        return Array.from(percentages);
    }

    function addPercentageData() {
        var sectionBody = document.querySelector(".fc-scrollgrid-section-body");
        if (sectionBody) {
            var cells = sectionBody.querySelectorAll(".fc-daygrid-day"); // Adjust selector if needed
            cells.forEach(function (cell, index) {
                if (index < weeklyPercentages.length) {
                    var percentage = weeklyPercentages[index];
                    if (!cell.classList.contains("percentage-added")) {
                        var percentageClass = percentage === 100 ? "percentage-100" : "percentage-data";
                        cell.innerHTML = `<div class="${percentageClass}">${percentage}%</div>`;
                        cell.classList.add("percentage-added");
                    }
                }
            });
        }
    }

    const handleDatesSet = (info) => {
        if (info.view.type === 'timeGridWeek') {
            formatTimeGridWeekHeaders(info.el);
            renderCustomAllDayHTML(info.el);
            renderCustomNavigationButtons(info.el);
            addPercentageData(info.el);

            const prevButton = info.el.querySelector('.custom-prev-week-button');
            if (prevButton) {
                prevButton.disabled = areAllDaysInPast(info.view.currentStart);
                prevButton.classList.toggle('fc-button-disabled', prevButton.disabled);
            }
        } else {
            const prevButton = document.querySelector('.custom-prev-week-button');
            if (prevButton) {
                prevButton.disabled = false;
                prevButton.classList.remove('fc-button-disabled');
            }
        }
    };

    const handleDateClick = (info) => {
        const today = new Date().setHours(0, 0, 0, 0);
        const dayOfWeek = info.date.getUTCDay();
        if (info.date >= today && dayOfWeek !== 5 && dayOfWeek !== 6) {
            // Date formatting and modal logic (replace with your implementation)
            console.log(`${info.date.toLocaleDateString('en-GB')}`);
        }
        if (info.view.type === 'dayGridMonth' && info.date.getDay() !== 0 && info.date.getDay() !== 6) {
            if (info.date.getDate() === 3) {
                info.el.innerHTML = `
          <div class="occupied-room">
            <span>80%</span>
            <div class="occupied-details">
              <div class="occupied-code">D023</div>
              <div class="occupied-credit"> +2 </div>
            </div>
          </div>`;
            }
        }
        if (info.date < new Date().setHours(0, 0, 0, 0)) {
            info.el.classList.add('fc-day-past');
        }
        setSelectedDate(info.dateStr);

        // Initialize and show the Bootstrap modal
        const modalElement = document.getElementById('dateModal');
        const modal = new Modal(modalElement);
        modal.show();
    };

    return (
        <div>
            <div className="content-area">
                <Header />
                <div className="component-area">
                    <Sidebar />
                    <div className="pt-96 my-container active-cont">
                        <div className="container">
                            <HeaderFilter />

                            {/* //Calendar */}
                            <div ref={setCalendarEl}>
                                <FullCalendar
                                    plugins={[dayGridPlugin, interactionPlugin]}
                                    initialView="dayGridMonth"
                                    firstDay={1}
                                    businessHours={{ daysOfWeek: [1, 2, 3, 4, 5], startTime: '09:00', endTime: '21:00' }}
                                    slotMinTime="09:00:00"
                                    slotMaxTime="21:00:00"
                                    slotDuration="01:00:00"
                                    slotLabelInterval="01:00:00"
                                    eventClick={handleEventClick}
                                    // hiddenDays= {[ 0,6 ]}
                                    // slotLabelContent={handleSlotLabelContent}
                                    customButtons={{
                                        checkInBtn: {
                                            text: 'Check In',
                                            click: handleCheckInButtonClick,
                                            classNames: ['my-custom-button'],
                                        },
                                    }}
                                    // headerToolbar={{
                                    //   left: 'checkInBtn',
                                    //   center: 'prev,title,next',
                                    //   right: false,
                                    // }}
                                    headerToolbar={{ left: 'checkInBtn', center: 'prev,title,next', right: false }}
                                    viewDidMount={handleViewMount}
                                    datesSet={handleDatesSet}
                                    dateClick={handleDateClick}
                                    // dayCellContent={renderDayCellContent}
                                    dayCellDid
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="dateModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="dateModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-custom-width">
                    <div className="modal-content modal-radius">
                        <div className="modal-body">
                            <div className="close-modal">
                                <img src={Close} alt="Close" data-bs-dismiss="modal" aria-label="Close" />
                            </div>
                            <div className="pt-4">
                                <h5 className="popup-title">New Booking</h5>
                            </div>
                            <div className="date-with-credit d-flex align-items-center justify-content-between pt-2">
                                <div id="selectedDate" className="selected-current-date">
                                    {selectedDate}
                                </div>
                                <div className="credit-info">
                                    <a href="#" className="orange-outline-with-icon">
                                        <img src={OrangeStar} alt="Star" /><span>-1</span>
                                    </a>
                                </div>
                            </div>
                            <div className="divider-line mt-3"></div>
                            <div className="mb-3">
                                <a href="#" className="green-outline-with-icon w-100 mw-100 min-height-56 selected">
                                    <img src={GreenMap} alt="Map" /><span>Go to Map</span>
                                </a>
                            </div>
                            <div className="">
                                <a href="#" className="green-outline-with-icon w-100 mw-100 min-height-56">
                                    <img src={EditSquare} alt="Edit" /><span>Get Random Space</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Check In Button */}
            {
                showModal && (
                    <div
                        className="modal fade show d-block"
                        id="checkinModal"
                        tabIndex="-1"
                        aria-labelledby="checkinModalLabel"
                        aria-hidden="true"
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
                    >
                        <div className="modal-dialog modal-dialog-centered modal-custom-width">
                            <div className="modal-content modal-radius">
                                <div className="modal-body">
                                    <div className="close-modal">
                                        <img
                                            src={Close}
                                            alt=""
                                            onClick={closeModal}
                                            aria-label="Close"
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                    <div className="pt-4">
                                        <h5 className="popup-title">Your Check In</h5>
                                    </div>
                                    <div className="date-with-credit d-flex align-items-center gap-4 pt-2 position-relative">
                                        <div id="date" className="current-date">
                                            {/* <h5>{formattedDate}</h5> */}
                                            <h5>{formattedOnlyDate}</h5>
                                            <h5>{formattedOnlyMonthShort}</h5>
                                        </div>
                                        <div className="location-info">
                                            <img
                                                src={MapOrange}
                                                className="img-fluid"
                                                alt=""
                                            />
                                            <p>Dublin Office Ground Floor</p>
                                        </div>
                                    </div>
                                    <div className="checkin-info">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Spot</th>
                                                    <th>Time</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {events.map((event) => (
                                                    <tr key={event.id}>
                                                        <td>{event.title.split(' - ')[0]}</td>
                                                        <td>{event.title.split(' - ')[1]}</td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                title={event.status === 'available' ? 'Check In' : 'Check Out'}
                                                                aria-pressed={event.status === 'checked-in'}
                                                                className={`fc-checkInBtn-button fc-button fc-button-primary ${event.status}`}
                                                                onClick={() => handleEventClick({ event })}
                                                            >
                                                                {event.status === 'available' ? 'Check In' : 'Check Out'}
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="mb-3">
                                        <a
                                            href="#"
                                            className="green-outline-with-icon w-100 mw-100 min-height-56 selected"
                                        >
                                            <img src="assets/images/icons/green-map.svg" alt="" />
                                            <span>Go to Map</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <Responsive />
        </div>
    )
}

export default Calendar