
// const Config = {
//     SETTING:'https://staging.ronspot.ie/api/v5/Profile_api',
//     REFRESH_TOKEN:"https://staging.ronspot.ie/api/v5/Token_api"
    
//     // LOGIN: 'https://staging.ronspot.ie/api/v4/User_login_api',
//     // REFRESH_TOKEN:'https://staging.ronspot.ie/api/v3/Token_api',
//     // SearchBookings_api:'https://staging.ronspot.ie/api/v3/SearchBookings_api',
//     // ParkingScheduleUpcoming_api:'https://staging.ronspot.ie/api/v3/ParkingScheduleUpcoming_api',
//     // DeviceRegister_api:'https://staging.ronspot.ie/api/v3/DeviceRegister_api',
    
//     // NOTIFICATION:'https://staging.ronspot.ie/api/v4/NotificationList_api',
//     // HOMESCREEN:'https://staging.ronspot.ie/api/v4/HomeScreenBookings_api',
//     // CARPARKLIST_ZONES:'https://staging.ronspot.ie/api/v4/CarParkList_api'
// }
import * as microsoftTeams from "@microsoft/teams-js";
import React, { useEffect } from "react";

const TestComponent = () => {
  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.getContext(context => {
      // console.log("Test Context:", context);
    });
  }, []);

  return (
    <div>
      Testing Teams Context
    </div>
  );
};

export default TestComponent;