// Login.js
import React, { useState } from 'react';
import axios from 'axios';
import Config from '../config';

const Login = ({ setTokens }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const response = await axios.post(Config.LOGIN, {
        ClientId: '8d76d67e5605ccf0f97a2ac5ac8bc3da',
        PrimaryEmail: "munaf.mansuri@bhavitech.com",
        PasswordT: "45b416115418c613d064c60e32acb231",
        PasswordN: "a10cec7c200c4bd99e53a9a698b5cc57c7dd3c62ef2c99e18008e770f780a116296d5f0a6f8659480d1181184d87319699e10ff952a7b4c25e6399faccc7f78d",
        // ClientId: "8d76d67e5605ccf0f97a2ac5ac8bc3da",
        Language: "english"
      });
      const { AccessToken, refreshToken } = response.data;
      localStorage.setItem('AccessToken', response.data.Records.AccessToken);
      localStorage.setItem('RefreshToken', response.data.Records.RefreshToken);
      setTokens(AccessToken, refreshToken);
      console.log( response.data)
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button onClick={handleLogin}>Login</button>
    </div>
  );
};

export default Login;
