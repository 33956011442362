import { useState, useEffect } from 'react'
import filterIcon from "../images/icons/filterIcon.svg"
import ButtonPopup from '../Calendar/WorkingCal';

import Close from "../images/icons/close.svg";
import Responsive from '../Responsive';

const HeaderFilter = () => {

  const [currentDate, setCurrentDate] = useState(new Date());

  const formattedDate = currentDate.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
  });

  const formattedOnlyDate = currentDate.toLocaleDateString('en-US', {
    day: 'numeric',
  });

  const formattedOnlyMonth = currentDate.toLocaleDateString('en-US', {
    month: 'long',
  });
  const formattedOnlyMonthShort = currentDate.toLocaleDateString('en-US', {
    month: 'short',
  });

  const formattedOnlyYear = currentDate.toLocaleDateString('en-US', {
    year: 'numeric'
  });


  const employeeOptions = [
    "Michael Furey",
    "John Warde",
    "Mathieu Pegeot",
    "Daire O Brien",
    "Angela Hernandez",
    "New User",
    "One more Added"
  ];

  const [showFromOptions, setShowFromOptions] = useState(false);

  const handleFromLabelClick = () => {
    setShowFromOptions(!showFromOptions);
  };
  return (
    <div>
      <div className="row">
        <div className="col-xl-9 offset-xl-3 col-lg-10 offset-lg-2">
          <div className="filter-panel d-flex justify-content-end align-items-center gap-3">
            <div className="filter-options d-flex gap-3 w-100">
              {/* <div className="filter-icon" data-bs-toggle="modal" data-bs-target="#filterPopup">
                <img src={filterIcon} alt="" />
              </div> */}
              {/* <ButtonPopup/> */}
              <div className="employee-list">
                <div className="dropdown form-input-container d-flex justify-content-center flex-column">
                  <Dropdown
                    dropdownBtnId="emp-dropdown-btn"
                    items={employeeOptions}
                    // defaultText="Michael Fureyjj"
                    label="Search by"
                  />
                </div>
              </div>
              <div className="booking-date align-items-center d-flex">
                <label for="datepicker-calender"
                >Calendar
                  <input type="text" id="datepicker-calender" autocomplete="off" placeholder="All bookings" />
                </label>
              </div>
            </div>
            {/* <div className="calendar-switcher">
              <input type="checkbox" className="toggle" id="toggle" />
              <label for="toggle">
                <span className="on" id="switch-to-month">Weekly</span>
                <span className="off" id="switch-to-week">Monthly</span>
              </label>
            </div> */}
          </div>
        </div>
      </div>

      {/* // Header Filter FilterIcon */}
      <div className="modal fade filterPopup" id="filterPopup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="filterPopupLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-custom-width">
          <div className="modal-content modal-radius">
            <div className="modal-body">
              <div className="close-modal">
                <img src={Close} alt="" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="pt-4">
                <h5 className="popup-title">Filters</h5>
              </div>

              <div id="unique-datepicker-section" className="d-flex custom-datepicker gap-3 position-relative">
                {/* <input type="text" id="monthpicker-unique" className="datepicker-input date-size" placeholder="DD" readonly /> */}
                <h5>{formattedOnlyDate}</h5>
                <div className="divider-vertical"></div>
                {formattedOnlyMonth}
                {/* <input type="text" id="dayyearpicker-unique" className="datepicker-input month-year-size" placeholder="MM/YY" readonly /> */}
                &nbsp;
                {formattedOnlyYear}
                {/* <input type="text" id="datepicker-unique" className="hidden-datepicker" /> */}
              </div>

              {/* <div className="date-with-credit d-flex align-items-center gap-4 pt-2 position-relative">
                      <div id="date" className="current-date">
                        <h5>{formattedDate}</h5>
                      </div>
                      <div className="location-info">
                        <img
                          src={MapOrange}
                          className="img-fluid"
                          alt=""
                        />
                        <p>Dublin Office Ground Floor</p>
                      </div>
                    </div> */}
              <div className="custom-timepicker pt-3">
                <h6 className="popup-inner-title">Select Time</h6>
                {/* <!-- <label for="time">Time</label> --> */}
                <div className="time-selection">
                  <div className="row">
                    <div className="col-md-6 rightArrow">
                      <div className="time-alignment pl20">
                        <label htmlFor="from-time" onClick={handleFromLabelClick}>From</label>
                        <input type="text" id="from-time" readonly placeholder="00.00" data-time="" />
                      </div>

                    </div>
                    <div className="col-md-6">
                      <div className="time-alignment pl40">
                        <label for="to-time" onClick={handleFromLabelClick}>To</label>
                        <input type="text" id="to-time" readonly placeholder="00.00" data-time="" />
                      </div>
                    </div>
                  </div>
                </div>
                {
                  showFromOptions && (
                    <div className="">
                      <div className="row">
                        <div className="col-md-12 position-relative">
                          <div id="from-options" className="time-options">
                            <div className="time-option disable">07:00</div>
                            <div className="time-option">08:00</div>
                            <div className="time-option selected">08:30</div>
                            <div className="time-option">11:00</div>
                            <div className="time-option">12:00</div>
                            <div className="time-option">13:00</div>
                            <div className="time-option">14:00</div>
                            <div className="time-option">15:00</div>
                            <div className="time-option">16:00</div>
                            <div className="time-option">17:00</div>
                            <div className="time-option">18:00</div>
                            <div className="time-option">20:00</div>
                            <div className="time-option">21:00</div>
                            <div className="time-option">22:00</div>
                            <div className="time-option">00:00</div>
                          </div>
                          <div id="to-options" className="time-options">
                            <div className="time-option disable">07:00</div>
                            <div className="time-option">08:00</div>
                            <div className="time-option selected">08:30</div>
                            <div className="time-option">11:00</div>
                            <div className="time-option">12:00</div>
                            <div className="time-option">13:00</div>
                            <div className="time-option">14:00</div>
                            <div className="time-option">15:00</div>
                            <div className="time-option">16:00</div>
                            <div className="time-option">17:00</div>
                            <div className="time-option">18:00</div>
                            <div className="time-option">20:00</div>
                            <div className="time-option">21:00</div>
                            <div className="time-option">22:00</div>
                            <div className="time-option">00:00</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="filter-facilities pt-3">
                <h6 className="popup-inner-title">Facilities</h6>
                <div className="inline-btn-tags tags-btn">
                  <button type="button" className="selected">Desk</button>
                  <button type="button">Meeting</button>
                  <button type="button">Parking</button>
                </div>
              </div>
              <div className="filter-tags pt-3">
                <h6 className="popup-inner-title">Tags</h6>
                <div className="inline-btn-tags tags-btn">
                  <button type="button" className="selected">WiFi</button>
                  <button type="button">Projector</button>
                  <button type="button">Window</button>
                  <button type="button">Mac</button>
                </div>
              </div>
              <div className="divider-line"></div>
              <div className="approval-action-btn d-flex justify-content-between">
                <button type="button" className="main-btn outline-gray-btn" data-bs-dismiss="modal">Cancel</button>
                <button type="button" className="main-btn fill-green-btn">Approve</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function Dropdown({ dropdownId, items, label }) {
  const [selectedItem, setSelectedItem] = useState(items[0]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };

  return (
    <div className="employee-list">
      <div className="dropdown form-input-container d-flex justify-content-center flex-column">
        <button
          className="dropdown-toggle form-control position-relative custom-dropdown-btn"
          type="button"
          id={dropdownId}
          data-bs-toggle="dropdown"
          aria-expanded="false"
          name="empdropdown"
          required
        >
          <p className="sub-dropdown-name">{label}</p>
          <span>{selectedItem}</span>
        </button>
        <ul className="dropdown-menu custom-dropdown" aria-labelledby={dropdownId}>
          {items.map((item, index) => (
            <li
              key={index}
              className={item === selectedItem ? "selected" : ""}
              onClick={() => handleItemClick(item)}
            >
              <a className="dropdown-item">
                {item}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <Responsive />
    </div>
  );
}

export default HeaderFilter