import React from "react";
import { createRoot } from "react-dom/client";
// import App from "./components/App";
import "./index.css";
import Login from "./components/Login";
import Tab from "./components/Tab";
import App from "./components/Temp/App";

// import "./components/i18n/i18n"
import Demo from "./components/Demo/Demo";
import LoadScripts from "./LoadScripts";
import LoginForm from "./components/LoginWebpage"
import Booking from "./components/Demo/Booking/Booking";
import Calendar from "./components/Demo/Calendar/Calendar";
import Bootstap from "./components/Demo/css/bootstrap.min.css"
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import "./components/Demo/css/bootstrap.min.css"
import "./components/Demo/css/calendar.css"
import "./components/Demo/css/common.css"
import "./components/Demo/css/reponsive.css"
import "./components/Demo/css/style.css"
import IFrame from "./components/Demo/Iframe";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <React.Suspense>
        {/* <Demo/> */}
        {/* <IFrame/> */}
         <Tab />
         {/* <Login/> */}
          {/* <LoginForm /> */}
          {/* <Booking/> */}
          {/* <Calendar/> */}
    </React.Suspense>
    // <App/>
);
