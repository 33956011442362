import Sidebar from '../Sidebar/Sidebar'
import filterIcon from "../images/icons/filterIcon.svg"
import greenCar from "../images/icons/green-car.svg"
import greenRoom from "../images/icons/green-room.svg"
import greenChair from "../images/icons/green-chair.svg"
import Header from '../Header/Header'
import Responsive from '../Responsive'
import HeaderFilter from '../Header/HeaderFilter'

export default function Booking() {
  return (
    <div>
      <div className='content-area'>
        <Header />
        <div className="component-area">
          <Sidebar />
          <div className="pt-96 pb-96 my-container active-cont">
            <div className="container">
              <HeaderFilter />
              <BookingCompo />
            </div>
          </div>
        </div>
      </div>
      <Responsive />
    </div>
  )
}

const BookingCompo = () => (
  <div className="row mt-md-4 mt-85 plr-40">
    <div className="col-md-12">
      <div className="module-heading-part d-flex gap-5 align-items-center mb-3">
        <h2 className="module-title">My bookings</h2>
        <p className="total-count">230 total</p>
      </div>
      <div className="table-container position-relative">
        <table id="dynamicTable" className="table-details">
          <thead>
            <tr>
              <th>Type</th>
              <th>Zone</th>
              <th>Date</th>
              <th>Name</th>
              <th>Start</th>
              <th>End</th>
              <th>extra</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className="name-with-icon">
                  <span><img src={greenCar} alt="" /></span>
                  <h3>Parking</h3>
                </div>
              </td>
              <td>Galway West</td>
              <td>Mon, 24 Feb</td>
              <td>No data</td>
              <td>13:00h</td>
              <td>17:00h</td>
              <td>extra</td>
            </tr>
            <tr>
              <td>
                <div className="name-with-icon">
                  <span><img src={greenRoom} alt="" /></span>
                  <h3>Meeting room</h3>
                </div>
              </td>
              <td>Grand Canal</td>
              <td>Sun, 04 Feb</td>
              <td>Launching WB</td>
              <td>13:00h</td>
              <td>17:00h</td>
              <td>extra</td>
            </tr>
            <tr>
              <td>
                <div className="name-with-icon">
                  <span><img src={greenChair} alt="" /></span>
                  <h3>Desk</h3>
                </div>
              </td>
              <td>Office West Dublin</td>
              <td>Mon, 24 Feb</td>
              <td>No data</td>
              <td>13:00h</td>
              <td>17:00h</td>
              <td>extra</td>
            </tr>
            <tr>
              <td>
                <div className="name-with-icon">
                  <span><img src={greenCar} alt="" /></span>
                  <h3>Parking</h3>
                </div>
              </td>
              <td>Galway West</td>
              <td>Mon, 24 Feb</td>
              <td>No data</td>
              <td>13:00h</td>
              <td>17:00h</td>
              <td>extra</td>
            </tr>
            <tr>
              <td>
                <div className="name-with-icon">
                  <span><img src={greenCar} alt="" /></span>
                  <h3>Parking</h3>
                </div>
              </td>
              <td>Galway West</td>
              <td>Mon, 24 Feb</td>
              <td>No data</td>
              <td>13:00h</td>
              <td>17:00h</td>
              <td>extra</td>
            </tr>
            <tr>
              <td>
                <div className="name-with-icon">
                  <span><img src={greenCar} alt="" /></span>
                  <h3>Parking</h3>
                </div>
              </td>
              <td>Galway West</td>
              <td>Mon, 24 Feb</td>
              <td>No data</td>
              <td>13:00h</td>
              <td>17:00h</td>
              <td>extra</td>
            </tr>
            {/* <!-- Add more rows as needed --> */}
          </tbody>
        </table>
        <div className="action-arrows">
          <button id="prevBtn" className="custom-prev-week-button"><span></span></button>
          <button id="nextBtn" className="custom-next-week-button"><span></span></button>
        </div>
      </div>
    </div>
  </div>
)
