import React, { useEffect, useRef, useState } from "react";
import graydelete from "../images/icons/gray-delete.svg"
import grayeye from "../images/icons/gray-eye.svg"
import redcross from "../images/icons/red-cross.svg"
import steering from "../images/icons/steering.svg"
import fuel from "../images/icons/fuel.svg"
import carwithmark from "../images/icons/car-with-mark.svg"
import menwithcycle from "../images/icons/men-with-cycle.svg"
import whiteplus from "../images/icons/white-plus.svg"
import tabletscreen from "../images/tablet-screen.svg"
import mobileonly from "../images/mobileonly.svg"
import appstore from "../images/appstore.svg"
import googleplay from "../images/googleplay.svg"
import greenclose from "../images/icons/green-close.svg"
import notifyLogoicon from "../images/notifyLogoicon.jpg"
import notifyProfile from "../images/notifyProfile.jpg"
import logosmall from "../images/logo-small.svg"
import close from "../images/icons/close.svg"
import orangestar from "../images/icons/orange-star.svg"
import greenmap from "../images/icons/green-map.svg"
import editsquare from "../images/icons/edit-square.svg"
import largecar from "../images/icons/large-car.svg"
import regularcar from "../images/icons/regular-car.svg"
import compactcar from "../images/icons/compact-car.svg"
import van from "../images/icons/van.svg"
import motorbike from "../images/icons/motorbike.svg"
import minibus from "../images/icons/minibus.svg"
import Sidebar from "../Sidebar/Sidebar";
import Responsive from "../Responsive";
import Header from "../Header/Header";
// import LanguageSelector from "../../i18n/language-selector";

export default function SettingReact() {
  const [activeTab, setActiveTab] = useState("profile-setting");
  const tabContainerRef = useRef(null);
  const tabLineRef = useRef(null);

  useEffect(() => {
    const updateTabLine = () => {
      if (tabContainerRef.current && tabLineRef.current) { // Check BOTH refs
        const activeTabElement = tabContainerRef.current.querySelector(".nav-link.active");
        if (activeTabElement) { // Check if activeTabElement is found
          const left = activeTabElement.offsetLeft;
          const width = activeTabElement.offsetWidth;
          tabLineRef.current.style.left = `${left}px`;
          tabLineRef.current.style.width = `${width}px`;
        } else {
          console.warn("Active tab element not found in updateTabLine");
        }
      } else {
        console.warn("tabContainerRef or tabLineRef not yet attached in updateTabLine");
      }
    };

    window.addEventListener("resize", updateTabLine);
    updateTabLine();

    return () => {
      window.removeEventListener("resize", updateTabLine);
    };
  }, [activeTab]);

  return (
    <div>
      <div className="content-area">
        <Header />
        {/* <IFrame/> */}
        <div className="component-area">
          <Sidebar />
          <div className="pt-96 my-container active-cont">
            <div className="container">
              <div className="row mt-md-4 mt-85">
                <div className="col-xl-8 offset-xl-2">
                  <div className="setting-options">
                    <ul className="nav nav-tabs" ref={tabContainerRef} id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${activeTab === "profile-setting" ? "active" : ""}`}
                          onClick={() => setActiveTab("profile-setting")}
                          type="button"
                          role="tab"
                        >
                          Profile
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${activeTab === "vehicle-setting" ? "active" : ""}`}
                          onClick={() => setActiveTab("vehicle-setting")}
                          type="button"
                          role="tab"
                        >
                          Vehicle
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${activeTab === "password-setting" ? "active" : ""}`}
                          onClick={() => setActiveTab("password-setting")}
                          type="button"
                          role="tab"
                        >
                          Password
                        </button>
                      </li>
                      <div
                        ref={tabLineRef}
                        style={{
                          position: "absolute",
                          bottom: "0",
                          height: "2px",
                          backgroundColor: "#1c988a",
                          transition: "left 0.3s ease, width 0.3s ease"
                        }}
                      ></div>
                    </ul>

                    <div className="tab-content" id="myTabContent">
                      <div className={`tab-pane fade ${activeTab === "profile-setting" ? "show active" : ""}`}>
                        <ProfileSettings />
                      </div>

                      <div className={`tab-pane fade ${activeTab === "vehicle-setting" ? "show active" : ""}`}>
                        <VehicleSettings />

                      </div>
                      <div className={`tab-pane fade ${activeTab === "password-setting" ? "show active" : ""}`}>
                        <PasswordSettings />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* {sprintf("Hello %s, your score is %d and your hex ID is %X.", "Alice", 95, 255)} */}
      </div>

      <Responsive />
    </div>
  );
}

const ProfileSettings = () => (
  <div className="setting-form-details">
    <div className="height-wrapper">
      <form action="#" className="form-elements mt-4">
        <div className="row g-4">
          <div className="col-md-6">
            <div className="form-group">
              <label for="fname">First name</label>
              <input type="text" className="form-control" id="fname" value="Michael" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="lname">Last name</label>
              <input type="text" className="form-control" id="lname" value="Furey" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="lname">Email</label>
              <input type="email" className="form-control" id="lname" value="info@telefonica.es" />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="group-options">Group</label>
              <div className="select-wrapper position-relative">
                <select className="form-control" id="group-options">
                  <option>MF Test Group</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="zone-options">Default zone</label>
              <div className="select-wrapper position-relative">
                <select className="form-control" id="zone-options">
                  <option>Central Park</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="lang-options">Language</label>
              <div className="select-wrapper position-relative">
                {/* <LanguageSelector /> */}
                <select className="form-control" id="lang-options">
                  <option>English</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="view-options">Default view</label>
              <div className="select-wrapper position-relative">
                <select className="form-control" id="view-options" onchange="location = this.value;">
                  <option value="index.html"><a>Calendar</a></option>
                  <option value="map.html"><a>Map</a></option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="switcher-list">
              <div className="form-check form-switch"><label className="form-check-label" for="remind-email">Receive Reminder Emails</label><input className="form-check-input" type="checkbox" id="remind-email" /></div>
              <div className="form-check form-switch"><label className="form-check-label" for="calendar-sync">Calendar Sync</label>
                <input className="form-check-input" type="checkbox" id="calendar-sync" /></div>
              <div className="form-check form-switch"><label className="form-check-label" for="remind-notify">Receive Reminder Notifications</label>
                <input className="form-check-input" type="checkbox" id="remind-notify" /></div>
            </div>
          </div>
        </div>
      </form>
      <div className="mt-4">
        <a href="#" className="gray-outline-with-icon"><img src={graydelete} alt="" /> <span>Delete My account</span></a>
      </div>
    </div>
    <div className="bottom-action-btn d-flex justify-content-end tags-btn gap-3">
      <button type="button" className="gray-outline-btn">Cancel</button>
      <button type="button" className="selected">Save changes</button>
    </div>
  </div>
);

const VehicleSettings = () => (
  <div className="setting-form-details">
    <div className="height-wrapper">
      <form action="#" className="form-elements mt-4">
        <div className="row g-4">
          <div className="col-md-6">
            <div className="form-group">
              <label for="fname">Vehicle 1</label>
              <input type="text" className="form-control" id="fname" value="Michael" />
            </div>
          </div>
          <div className="col-md-3">
            <div className="size-type-options">
              <div className="dropdown form-input-container d-flex justify-content-center flex-column rect-dropdown rect-dropdown-without-arrow">
                <button className="dropdown-toggle form-control position-relative custom-dropdown-btn" type="button" id="size-type-btn" data-bs-toggle="dropdown" aria-expanded="false" name="deskdropdown" required>
                  <img src={steering} alt="" />
                  <span>Size type</span>
                </button>
                <ul className="dropdown-menu custom-dropdown" aria-labelledby="size-type" id="size-type">
                  <li className="selected"><a className="dropdown-item" href="javascript:void(0);">Regular Car</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="vehicle-options">
              <div className="dropdown form-input-container d-flex justify-content-center flex-column rect-dropdown">
                <button className="dropdown-toggle form-control position-relative custom-dropdown-btn" type="button" id="fuel-option-btn" data-bs-toggle="dropdown" aria-expanded="false" name="deskdropdown" required>
                  <img src={fuel} alt="" />
                  <span>Petrol</span>
                </button>
                <ul className="dropdown-menu custom-dropdown" aria-labelledby="fuel-option" id="fuel-option">
                  <li className="selected"><a className="dropdown-item" href="javascript:void(0);">Petrol</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Diesel</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Electric Vehicle</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Plug-In Hybr</a></li>
                </ul>
              </div>
              <div className="dropdown form-input-container d-flex justify-content-center flex-column rect-dropdown">
                <button className="dropdown-toggle form-control position-relative custom-dropdown-btn" type="button" id="accesible-option-btn" data-bs-toggle="dropdown" aria-expanded="false" name="deskdropdown" required>
                  <img src={carwithmark} alt="" />
                  <span>Accessible</span>
                </button>
                <ul className="dropdown-menu custom-dropdown" aria-labelledby="accesible-option" id="accesible-option">
                  <li className="selected"><a className="dropdown-item" href="javascript:void(0);">Non accessible</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Accessible</a></li>
                </ul>
              </div>
              <div className="dropdown form-input-container d-flex justify-content-center flex-column rect-dropdown">
                <button className="dropdown-toggle form-control position-relative custom-dropdown-btn" type="button" id="shareable-option-btn" data-bs-toggle="dropdown" aria-expanded="false" name="deskdropdown" required>
                  <img src={menwithcycle} alt="" />
                  <span>Shareable</span>
                </button>
                <ul className="dropdown-menu custom-dropdown" aria-labelledby="shareable-option" id="shareable-option">
                  <li className="selected"><a className="dropdown-item" href="javascript:void(0);">Shareable</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Desk</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Parking</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Assets</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row g-4">
          <div className="col-md-6">
            <div className="form-group">
              <label for="fname">Vehicle 2</label>
              <input type="text" className="form-control" id="fname" value="Michael" />
            </div>
            <div className="mt-4">
              <a href="#" className="green-filled-with-icon"
                // data-bs-toggle="modal"
                data-bs-target="#vehicle-type-modal">
                <span><img src={whiteplus} alt="" /></span> Add Vehicle</a>
            </div>
          </div>
          <div className="col-md-3">
            <div className="size-type-options">
              <div className="dropdown form-input-container d-flex justify-content-center flex-column rect-dropdown rect-dropdown-without-arrow">
                <button className="dropdown-toggle form-control position-relative custom-dropdown-btn" type="button" id="size-type-btn2" data-bs-toggle="dropdown" aria-expanded="false" name="deskdropdown" required>
                  <img src={steering} alt="" />
                  <span>Size type</span>
                </button>
                <ul className="dropdown-menu custom-dropdown" aria-labelledby="size-type2" id="size-type2">
                  <li className="selected"><a className="dropdown-item" href="javascript:void(0);">Regular Car</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="vehicle-options">
              <div className="dropdown form-input-container d-flex justify-content-center flex-column rect-dropdown">
                <button className="dropdown-toggle form-control position-relative custom-dropdown-btn" type="button" id="fuel-option-btn" data-bs-toggle="dropdown" aria-expanded="false" name="deskdropdown" required>
                  <img src={fuel} alt="" />
                  <span>Petrol</span>
                </button>
                <ul className="dropdown-menu custom-dropdown" aria-labelledby="fuel-option" id="fuel-option">
                  <li className="selected"><a className="dropdown-item" href="javascript:void(0);">Petrol</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Diesel</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Electric Vehicle</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Plug-In Hybr</a></li>
                </ul>
              </div>
              <div className="dropdown form-input-container d-flex justify-content-center flex-column rect-dropdown">
                <button className="dropdown-toggle form-control position-relative custom-dropdown-btn" type="button" id="accesible-option-btn" data-bs-toggle="dropdown" aria-expanded="false" name="deskdropdown" required>
                  <img src={carwithmark} alt="" />
                  <span>Accessible</span>
                </button>
                <ul className="dropdown-menu custom-dropdown" aria-labelledby="accesible-option" id="accesible-option">
                  <li className="selected"><a className="dropdown-item" href="javascript:void(0);">Non accessible</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Accessible</a></li>
                </ul>
              </div>
              <div className="dropdown form-input-container d-flex justify-content-center flex-column rect-dropdown">
                <button className="dropdown-toggle form-control position-relative custom-dropdown-btn" type="button" id="shareable-option-btn" data-bs-toggle="dropdown" aria-expanded="false" name="deskdropdown" required>
                  <img src={menwithcycle} alt="" />
                  <span>Shareable</span>
                </button>
                <ul className="dropdown-menu custom-dropdown" aria-labelledby="shareable-option" id="shareable-option">
                  <li className="selected"><a className="dropdown-item" href="javascript:void(0);">Shareable</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Desk</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Parking</a></li>
                  <li><a className="dropdown-item" href="javascript:void(0);">Assets</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div className="bottom-action-btn d-flex justify-content-end tags-btn gap-3">
      <button type="button" className="gray-outline-btn">Cancel</button>
      <button type="button" className="selected">Save changes</button>
    </div>
  </div>
);

const PasswordSettings = () => (
  <div className="setting-form-details">
    <div className="height-wrapper">
      <form action="#" className="form-elements mt-4">
        <div className="row g-4">
          <div className="col-md-6">
            <div className="form-group">
              <label for="fname">Your password</label>
              <div className="psw-bind position-relative">
                <input type="password" className="form-control" id="fname" value="N4wcompany!" />
                <img src={grayeye} alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label for="fname">New password</label>
              <div className="psw-bind position-relative">
                <input type="password" className="form-control" id="new-password" placeholder="Password" />
                <img src={grayeye} alt="" />
              </div>
              <div className="password-strength">
                <div className="progress-bar">
                  <div id="strength-bar"></div>
                </div>
                <p><span id="strength-label">Low</span></p>
              </div>
              <div className="password-requirements">
                <p id="length-check">
                  <span className="icon"><img src={redcross} alt="" /></span> At least 10 characters
                </p>
                <p id="number-uppercase-check">
                  <span className="icon"><img src={redcross} alt="" /></span> Include at least one number and one uppercase letter
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div className="bottom-action-btn d-flex justify-content-end tags-btn gap-3">
      <button type="button" className="gray-outline-btn">Cancel</button>
      <button type="button" className="selected">Save changes</button>
    </div>
  </div>
);
