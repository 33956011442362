// import "../css/bootstrap.min.css"
// import "../css/common.css"
// import "../css/style.css"
// import "../css/calendar.css"
// import "../css/reponsive.css"

import logo from "../images/logo.svg"
import arrowwithgreenbg from "../images/arrow-with-green-bg.svg"
import dashboard from "../images/icons/dashboard.svg"
import bookings from "../images/icons/bookings.svg"
import colleagues from "../images/icons/colleagues.svg"
import suitcase from "../images/icons/suitcase.svg"
import settings from "../images/icons/settings.svg"
import logout from "../images/icons/logout.svg"
import { useEffect, useRef } from "react"
import { NavLink } from "react-router-dom"
import { useTranslation } from "react-i18next"

export default function Sidebar() {

    const sidebarRef = useRef(null);
    const { t } = useTranslation()

    useEffect(() => {
        loadSidebar()
    })
    const loadSidebar = (() => {
        var menu_btn = document.querySelector("#menu-btn");
        var sidebar = document.querySelector("#sidebar");
        var container = document.querySelector(".my-container");
        if (menu_btn && sidebar && container) {
            menu_btn.addEventListener("click", () => {
                sidebar.classList.toggle("active-nav");
                container.classList.toggle("active-cont");
            });
        }

        var path = window.location.pathname.split("/").pop();
        if (path == "") {
            path = "index.html";
        }
    })
    //   var target = $('#accordian li a[href="' + path + '"]');

    //   // Add active class to target link's parent li element
    //   target.parents("li").addClass("active");

    //   // Show the dropdown if inside a nested list (if applicable)
    //   target.parents("ul").addClass("show-dropdown");

    return (
        <div className="side-navbar active-nav d-flex flex-wrap flex-column justify-content-between"
            id="sidebar"
            ref={sidebarRef}
        >
            <a href="#" className="nav-link text-center pt-4 logo">
                <img src={logo} alt="" className="img-fluid logo" />
            </a>
            <div className="pb-3 open-close">
                <a className="btn border-0" id="menu-btn"><img src={arrowwithgreenbg} alt="" /></a>
            </div>
            <div id="accordian" className="flex-grow-1">
                <ul className="show-dropdown menu-style">
                    {/* <li>
                        <a href="Calendar.jsx" data-tooltip="Calendar">
                            <img src={dashboard} alt="" /> <span>Calendar</span></a>
                    </li> */}
                    <li>
                        <NavLink to={"/Calendar"} data-tooltip="Calendar"> <img src={dashboard} alt="" />
                            <span>{t("calendar")}</span></NavLink>
                    </li>
                    <li>
                        <NavLink to={"/booking"} data-tooltip="My bookings"> <img src={bookings} alt="" />
                            <span>{t("My bookings")}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/colleagues"} data-tooltip="Find colleagues"> <img src={colleagues} alt="" />
                            <span>{t("Find colleagues")}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/roles"} data-tooltip="Employee roles"> <img src={suitcase} alt="" />
                            <span>{t("Employee roles")}</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={"/setting"} data-tooltip="Settings"> <img src={settings} alt="" />
                            <span>{t("Settings")}</span></NavLink>
                    </li>
                </ul>
            </div>
            <div className="button-style">
                <a href="#" className="green-outline-with-icon mx-auto mb-4 f12 logout-btn"> <img src={logout} alt="" /> Logout</a>
            </div>
        </div>
    )
}
