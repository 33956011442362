import axios from 'axios';
import Config from './config';

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('AccessToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        console.log(error.response.status,"error.response.status")
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('RefreshToken');
      const guid = localStorage.getItem('GuID');
      try {
        const response = await axios.post(Config.REFRESH_TOKEN, {
            // GuID: guid,
            // RefreshToken: refreshToken,
            AppVersion: "12.15.0",
            RefreshToken: {refreshToken},
            Language: "english",
            DeviceId: "c90d1fa3-e837-46e7-a5e2-15a9ad5ce21d",
            OsVersion: "11",
            ClientId: "8d76d67e5605ccf0f97a2ac5ac8bc3da",
            GrantType: "User",
            GuId: "EB85CA39-72E6-43EA-468A-7A454D363462",
            ManufactureModel: "RMX2027"
        });
        const newAccessToken = response.data.Data.Records.AccessToken;
        localStorage.setItem('AccessToken', newAccessToken);
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        console.error('Failed to refresh token:', err);
        // Redirect to login if refresh token fails
        alert("Your session has expired. please log in")
        window.location.href = '/login';
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
