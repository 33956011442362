import React from 'react'
import tabletscreen from "./images/tablet-screen.svg"
import mobileonly from "./images/mobileonly.svg"
import appstore from "./images/appstore.svg"
import googleplay from "./images/googleplay.svg"

export default function Responsive() {
    return (
        <div>
            <div className="tablet-screen-msg">
                <div className="rotate-msg">
                    <div>
                        <h2>Please rotate your iPad!</h2>
                        <p>We support landscape mode only</p>
                    </div>
                    <img src={tabletscreen} alt="" className="img-fluid" />
                </div>
            </div>

            <div className="mobile-screen-msg">
                <img src={mobileonly} alt="" className="img-fluid" />
                <div className="app-msg pb-2">
                    <h2>ATTENTION!</h2>
                    <p>To continue we need you to download <br />the Ronspot app.</p>
                </div>
                <div className="playstore">
                    <img src={appstore} alt="" className="img-fluid" />
                    <img src={googleplay} alt="" className="img-fluid" />
                </div>
            </div>
        </div>
    )
}
