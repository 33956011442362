import * as microsoftTeams from "@microsoft/teams-js";
import React, { useState, useEffect } from "react";

const IFrame = () => {
  const [homeAccountId, setHomeAccountId] = useState(null);
  const [error, setError] = useState(null); // State for error message

  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.getContext(async (context) => {
      const accountId = context.userObjectId;
      // const accountId = context.userPrincipalName;
      setHomeAccountId(accountId);

      if (accountId) {
        localStorage.setItem('msHomeAccountId', accountId);
        window.dispatchEvent(new CustomEvent('msHomeAccountIdReceived', { detail: accountId }));
      } else {
        setError("Error: Could not retrieve Microsoft Teams account ID."); // Set error message
      }
    });

        const handleAccountReceived = (event) => {
            console.log("Account ID received in iframe:", event.detail);
            // ... any other logic you need in the iframe
          }
          window.addEventListener('msHomeAccountIdReceived', handleAccountReceived);
      
          return () => { // Cleanup: Remove event listener when component unmounts
            window.removeEventListener('msHomeAccountIdReceived', handleAccountReceived);
          };
  }, []);

  if (error) {
    return <div>{error}</div>; // Display the error message
  }

  return null;
};

export default IFrame;
/// Function Component
// import * as microsoftTeams from "@microsoft/teams-js";
// import React, { useState, useEffect } from "react";

// const IFrame = () => {
//     const [homeAccountId, setHomeAccountId] = useState("");

//     useEffect(() => {
//         microsoftTeams.initialize();
//         microsoftTeams.getContext(context => {
//             console.log("Teams Context:", context); // Log the whole context for debugging
//             setHomeAccountId(context.userObjectId); // Use userObjectId directly
//         });
//     }, []);

//     const url = `https://my.ronspot.ie/teams/react/login/${homeAccountId}`;

//     return (
//         <div>
//             {/* <iframe
//                 src={url}
//                 width="100%"
//                 height="100%"
//                 sandbox="allow-forms allow-modals allow-presentation allow-same-origin allow-scripts allow-downloads"
//             /> */}
//             <p>Home Account ID: {homeAccountId}</p> {/* Display for testing */}
//         </div>
//     );
// };

// export default IFrame;



///CLASS Component
// import * as microsoftTeams from "@microsoft/teams-js";
// import { Component } from "react";

// export default class IFrame extends Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             username: "",
//             homeAccountId: ""
//         };
//     }

//     componentDidMount() {
//         microsoftTeams.initialize();
//         microsoftTeams.getContext(context => {
//             this.setState({ username: context.userPrincipalName });
//             this.setState({ homeAccountId: context.userObjectId })
//             console.log(context)
//             console.log(context.userPrincipalName)
//         });
//         this.acquireToken();
//     }

//     acquireToken = async () => {
//         const tokenRequest = {
//             scopes: ["User.Read"]
//         };

//         try {
//             const response = await this.msalClient.acquireTokenSilent(tokenRequest);
//             const accessToken = response.accessToken;
//             this.fetchUserProfile(accessToken);
//         } catch (error) {
//             console.error("Error acquiring token:", error);
//         }
//     };

//     fetchUserProfile = async (accessToken) => {
//         const graphAPIEndpoint = "https://graph.microsoft.com/v1.0/me";
//         const headers = new Headers();
//         headers.append("Authorization", `Bearer ${accessToken}`);

//         const options = {
//             method: "GET",
//             headers: headers
//         };

//         try {
//             const profileResponse = await fetch(graphAPIEndpoint, options);
//             const profileData = await profileResponse.json();
//             const homeAccountId = profileData.id;
//             console.warn({homeAccountId})
//             this.setState({ homeAccountId });
//         } catch (error) {
//             console.error("Error fetching user profile:", error);
//         }
//     };

//     render() {
//         const { username, homeAccountId } = this.state;
//         var url = `https://my.ronspot.ie/teams/react/login/${homeAccountId}`

//         return (
//             <div>
//                 {/* <iframe
//                     src={url}
//                     width="100%"
//                     height="100%"
//                     sandbox="allow-forms allow-modals allow-presentation allow-same-origin allow-scripts allow-downloads"
//                 /> */}
//             </div>
//         );
//     }
// }
